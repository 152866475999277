<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-7 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row">
                  <div class="col-10 offset-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        <span class="d-none d-md-inline-block">VIEW&nbsp;</span>DONATION: {{item.transactionReference}}
                        <div class="row m-0 justify-content-center" >
                          <div class="col-6 col-md-12 text-center pt-md-3 pt-4 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row mx-0 my-4 justify-content-center align-items-center" >
                  <div class="col-12 col-md-auto text-center">
                    <div class="profile_img m-auto">
                      <Image :imageName="item.user.imageUrl" width="50" v-if="item.user.imageUrl" />
                      <IconUser size="sizefull" v-else />
                    </div>
                  </div>
                  <div class="col-12 col-md-auto p-0 text-center text-md-start">
                    <div class="bold green-text font19">
                      <span v-if="item.donationMadeBy">{{item.donationMadeBy.firstName}} {{item.donationMadeBy.lastName}}</span>
                      <span v-else>{{item.user.firstName}} {{item.user.lastName}}</span>
                    </div>
                    <div class="medium green-text font10 letter_spacing">
                      DONOR ID: <span v-if="item.donationMadeBy">{{item.donationMadeBy.userID}}</span><span v-else>{{item.user.userID}}</span>
                    </div>
                  </div>
                </div>
                <div class="row m-0 justify-content-center" >
                  <div class="col-11 px-0 my-3 card_item_inner">
                    <div class="row mx-0 h-100 justify-content-center">
                      <div class="col-12 p-0">
                        <div class="row m-0 justify-content-center">
                          <div class="col-12 p-0 border_top" v-for="(donation, key) in item.donationList" :key="key">
                            <div class="row m-0 align-items-center borders py-2">
                              <!--IMAGE-->
                              <div class="col-12 text-center col-md-auto px-4">
                                <Image :imageName="projectImage(donation.project.projectID)" width="40" theClass="item_img" v-if="projectImage(donation.project.projectID)" />
                                <!-- <img :src="url + '/static/uploads/images/' + projectImage(donation.project.projectID)"  class="item_img" v-if="projectImage(donation.project.projectID)"> -->
                                <img src="../assets/images/default.png" class="item_img" v-else>
                              </div>
                              <!--END IMAGE-->
                              <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
                                <div class="row m-0">
                                  <div class="col-md-12 p-0 green-text bold item">
                                    {{donation.project.description}}
                                  </div>
                                  <div class="col-md-12 p-0 font12">
                                    {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}} {{ donation.donationType.description }}
                                  </div>
                                </div>
                              </div>
                              <div class="col col-md-auto text-center text-md-end bold mt-3 mt-md-0 pe-4 item">
                                {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- ADD BACK WHEN API HAS FUNCTIONALITY -->
                      <div class="col-12 p-0 align-self-end" v-if="item.chargeAmount">
                        <div class="row m-0 background-grey py-3 medium align-items-center">
                          <div class="col-md px-md-4 font12 text-center text-md-start">
                            Contribution towards Processing Fees:<span class="d-none d-md-inline"><br/>Your commitment to help your donation stay 100%</span>
                          </div>
                          <div class="col-md-auto text-center text-md-end bold mt-1 mt-md-0 pe-4 item">
                            {{ defaultCurrency }} {{ parseFloat(Math.round(item.chargeAmount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                          </div>
                        </div>
                      </div>
                      <!-- ADD BACK WHEN API HAS FUNCTIONALITY -->
                    </div>
                  </div>
                </div>
                <div class="row m-0 justify-content-center" >
                  <!--MOBILE-->
                  <div class="col-11 px-0 my-3 d-md-none">
                    <div class="row align-items-center">
                      <div class="col-6 px-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="item.paymentOption.method" />
                      </div>
                      <div class="col-6 px-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6 px-2 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          PAYMENT METHOD
                        </div>
                      </div>
                      <div class="col-6 px-2 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION DATE
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-12 text-center">
                        <Button color="green" size="big" width="100" :btnText="displayTotal" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION TOTAL
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END MOBILE-->
                  <!--DESKTOP-->
                  <div class="col-11 px-0 my-3 d-none d-md-block">
                    <div class="row align-items-center">
                      <div class="col-4 text-center green-text">
                        <Button color="grey" width="100" :btnText="item.paymentOption.method" />
                      </div>
                      <div class="col-4 text-center">
                        <Button color="green" size="big" width="100" :btnText="displayTotal" />
                      </div>
                      <div class="col-4 text-center green-text">
                        <Button color="grey" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          PAYMENT METHOD
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION TOTAL
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION DATE
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END DESKTOP-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end">
            <div class="row m-0 background-green py-3 align-items-center">
              <div class="col-md-auto text-end d-none d-md-flex">
                <Button color="dark" size="xsmall" btnText="18A" icon="arrow" icon2="arrow" class="opacity" width="100" alignment="justify-content-between" disabled>
                  <IconDownload size="size9" />
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
              </div>
              <div class="col-md">
                <div class="row" v-if="item.transactionStatus.transactionStatusID === 14 || item.transactionStatus.transactionStatusID === 5 || item.transactionStatus.transactionStatusID === 4 || item.transactionStatus.transactionStatusID === 12">
                  <div class="col-md-6 px-2 mb-2 mb-md-0" >
                    <Button color="green_light" size="" width="100" btnText="Sending..." icon="arrow" v-if="emailReceiptStatus === 'sending'" disabled>
                       <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </Button>
                    <Button color="green_light" size="" width="100" btnText="Sent" icon="arrow" disabled v-else-if="emailReceiptStatus === 'sent'">
                      <ios-checkmark-circle-icon class="inline-icon" />
                    </Button>
                    <Button color="green_light" size="" width="100" btnText="Email Receipt" icon="arrow" @buttonClicked="emailReceipt" :disabled="item.transactionStatus.transactionStatusID !== 14 && item.transactionStatus.transactionStatusID !== 5 && item.transactionStatus.transactionStatusID !== 4 && item.transactionStatus.transactionStatusID !== 12" v-else>
                      <IconMail />
                    </Button>
                  </div>
                  <div class="col-md-6 px-2 mb-2 mb-md-0" >
                    <Button color="green_light" size="" width="100" btnText="Downloading" icon="arrow" v-if="downloadReceiptStatus === 'downloading'" disabled>
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </Button>
                    <Button color="green_light" size="" width="100" btnText="Downloaded" icon="arrow" disabled v-else-if="downloadReceiptStatus === 'downloaded'">
                      <ios-checkmark-circle-icon class="inline-icon" />
                    </Button>
                    <Button color="green_light" size="" width="100" btnText="Download Receipt" icon="arrow" @buttonClicked="downloadReceipt" :disabled="item.transactionStatus.transactionStatusID !== 14 && item.transactionStatus.transactionStatusID !== 5 && item.transactionStatus.transactionStatusID !== 4 && item.transactionStatus.transactionStatusID !== 12" v-else>
                      <IconDownload size="size16"/>
                    </Button>
                  </div>
                </div>
                <div class="row justify-content-center" v-else>
                  <div class="col-md-6 px-2 mb-2 mb-md-0" >
                    <Button color="green_light" size="" width="100" btnText="Receipt Not Available" disabled />
                  </div>
                </div>
              </div>
              <div class="col-md-auto text-end d-none d-md-flex">
                <Button color="dark" size="xsmall" btnText="18A" icon="arrow" icon2="arrow" class="white-text" width="100" alignment="justify-content-between" @buttonClicked="download18A" v-if="canDownload18">
                  <IconDownload size="size9" />
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
                <Button color="dark" size="xsmall" btnText="18A" icon2="arrow" class="white-text" width="100" alignment="justify-content-between" v-else-if="isSection18" @buttonClicked="popReason = !popReason">
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
                <Button color="dark" size="xsmall" btnText="18A" icon2="arrow" class="white-text" width="100" alignment="justify-content-between" disabled v-else>
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
              </div>
              <div class="col-12 text-end d-md-none">
                <Button color="dark" size="small" btnText="Download 18A Certificate" icon="arrow" icon2="arrow" class="white-text" width="100" alignment="justify-content-between" @buttonClicked="download18A" v-if="canDownload18">
                  <IconDownload size="size9" />
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
                <Button color="dark" size="small" btnText="18A But Certificate Not Available" icon="arrow" icon2="arrow" class="white-text" width="100" alignment="justify-content-between" @buttonClicked="popReason = !popReason" v-else-if="isSection18">
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
                <Button color="dark" size="small" btnText="Download 18A Certificate" icon="arrow" icon2="arrow" class="white-text" width="100" alignment="justify-content-between" disabled v-else>
                  <template v-slot:iconStart>
                    <ios-checkmark-circle-icon class="gold-text"/>
                  </template>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div class="abs_popup" v-if="popReason" v-click-outside="closePop">
          <div class="row mx-0">
            <div class="col-12 p-3 text-center small text-white">
              <div class="bold mb-2">
                Cannot issue 18A Certificate
              </div>
              <div class="mb-2">
                Please check if the donor has a<br/>valid SA ID Number, Tax Number,<br/>Email Address and Cell Number.
              </div>
              <div>
                <Button color="goldGreen" size="small" btnText="Update my details"  width="100" alignment="justify-content-between" @buttonClicked="updateDetails" />
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute w-100 start-0 d-none d-md-block">
          <div class="row my-4 mx-0 justify-content-center w-100">
            <div class="col-6 col-sm-5 col-md-3 col-xl-2 item_nav rounded-pill p-2 text-white bold">
              <div class="row align-items-center py-1 justify-content-between w-100 m-0">
                <div class="col-auto cursor hover" @click="goBack" v-if="theKey > 0">
                  <IconArrowBack />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowBack />
                </div>
                <div class="col p-0 text-center">
                  {{theKey + 1}}/{{totalItems}}
                </div>
                <div class="col-auto cursor hover" @click="goForward" v-if="theKey +  1 < totalItems">
                  <IconArrowForward />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowForward />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Popup v-if="editDetails" @close="(editDetails = false)">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Edit Details
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col-12 mb-3 px-1" v-if="!isUK" ref="theIDNumber">
                <TextField type="text" label="SA ID Number:" placeholder="ID Number" v-model="profile.idNumber" id="idNumber" color="gold" :errors="!idError ? [] : ['Error']" :hasErrors="!idError ? 0 : 1" />
              </div>
              <div class="col-12 mb-3 px-1" v-if="!isUK">
                <TextField type="text" label="Tax Number:" placeholder="Tax Number" v-model="profile.individualTaxNumber" id="individualTaxNumber" color="gold" />
              </div>
              <div class="col-12 mb-3 px-1" v-if="!isUK">
                <TextField type="email" label="Tax Certificate Email Address:" placeholder="Email Address" v-model="profile.taxCertificateEmailAddress" id="taxCertificateEmailAddress" color="gold" />
              </div>
              <div class="col-12 mb-3 px-1">
                <PhoneFieldInt type="tel" :showError="!isValid" label="Mobile No.:" placeholder="Mobile Number" v-model:modelValue="profile.cell" v-model:theError="isValidNumber" id="cell" color="gold" ></PhoneFieldInt>
              </div>
            </div>
          </div>
          <div class="col-11 text-center">
            <Button color="gold" btnText="Save" icon="arrow" @buttonClicked="update18aDetails" :isLoading="isLoading">
              <IconArrowForward />
            </Button>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'
import vClickOutside from 'click-outside-vue3'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconMail: defineAsyncComponent(() => import('../components/icons/IconMail.vue')),
    IconDownload: defineAsyncComponent(() => import('../components/icons/IconDownload.vue')),
    IconUser: defineAsyncComponent(() => import('../components/icons/IconUser.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('../components/PhoneFieldInt.vue')),
    Popup: defineAsyncComponent(() => import('./Popup.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-checkmark-circle-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark-circle.vue'))
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'Donation Popup',
  props: ['item', 'theKey', 'totalItems'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      url: process.env.VUE_APP_URL_BASE,
      canDownload18: false,
      popReason: false,
      editDetails: false,
      profile: {},
      isLoading: false,
      idError: false
    }
  },
  mounted () {
    this.checkSection18A()
  },
  watch: {
    item: {
      deep: true,
      handler (val) {
        this.checkSection18A()
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'contentProjects',
      'emailReceiptStatus',
      'downloadReceiptStatus',
      'section18ATransactions',
      'user'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isSection18 () {
      const i = this.section18ATransactions.findIndex(e => e.transactionID === this.item.transactionID)
      if (i > -1) {
        return true
        /* vendors contains the element we're looking for, at index "i" */
      } else {
        return false
      }
    },
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.transactionDate))
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.item.totalBankChargeAmount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  methods: {
    ...mapActions(['check18A', 'downloadSection18', 'updateUserDetails', 'verifyIDNumber']),
    async checkSection18A () {
      const status = await this.check18A(this.item.transactionID)
      this.canDownload18 = status.canDownloadTaxCertificate
    },
    closePop () {
      this.popReason = false
    },
    projectImage (val) {
      const adminProject = this.adminProjects.find(project => project.projectID === val)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === val)
      if (contentProject) {
        const mergedProject = { ...adminProject, ...contentProject }
        if (mergedProject.imageUrl) {
          return mergedProject.imageUrl
        } else {
          return null
        }
      } else {
        return null
      }
    },
    goBack () {
      this.$emit('goBack')
    },
    goForward () {
      this.$emit('goForward')
    },
    downloadReceipt () {
      this.$emit('downloadReceipt')
    },
    async download18A () {
      await this.downloadSection18(this.item.transactionID)
    },
    emailReceipt () {
      this.$emit('emailReceipt')
    },
    updateDetails () {
      this.profile = { ...this.user }
      this.editDetails = true
      // this.$router.push('/profileedit')
    },
    async update18aDetails () {
      this.isLoading = true
      let idRet = true
      if (!this.isUK && this.profile.idNumber) {
        idRet = await this.verifyID()
      }
      if (idRet) {
        const details = {
          id: this.profile.id,
          email: this.profile.email,
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          title: this.profile.userTitleID,
          zakaatCalculationDate: this.profile.zakaatCalculationDate,
          cellNumber: this.profile.cell,
          giftAid: this.profile.giftAid,
          individualTaxNumber: this.profile.individualTaxNumber,
          taxCertificateEmailAddress: this.profile.taxCertificateEmailAddress,
          idNumber: this.profile.idNumber
        }

        await this.updateUserDetails(details)
        await this.checkSection18A()
        if (this.canDownload18) {
          this.download18A()
        }
        this.isLoading = false
        this.editDetails = false
      }
      // actually submit form
    },
    async verifyID () {
      const ret = await this.verifyIDNumber(this.profile.idNumber)
      if (ret === true) {
        this.idError = false
      } else {
        this.idError = true
        this.isLoading = false
      }
      return ret
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  /* max-height: 40vh; */
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
  /* max-height: 88vh; */
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
.abs_popup {
  background-color: var(--green-color);
  bottom: 3%;
  position: absolute;
  border-radius: 15px;
  z-index: 9;
  left: 50%;
  transform: translate(-50%,-50%)!important;
  max-width: 90%;
  width: 70%;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 50%);
}
@media (min-width: 992px) {
  .card_item {
    max-height: 80vh;
  }
  .card_item_inner {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 35vh;
  }
  .above {
    max-height: 78vh;
  }
  .abs_popup {
    background-color: rgba(0, 0, 0, 0.8);
    right: 13%;
    left: inherit;
    bottom: initial;
    transform: translate(0%,0%)!important;
    width: max-content;
  }

}
</style>
